import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const AppStoreButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  > a, div {
    height: 3.5rem;

    &:not(:first-child) {
      margin-left: 1.125rem;
    }
  }

  ${breakpointsMedia({
    md: css`
      justify-content: flex-start;
    `,
  })}
`;

AppStoreButtonsContainer.displayName = 'AppStoreButtonsContainer';

export default AppStoreButtonsContainer;
