import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Image from '@nubank/nuds-web/components/Image/Image';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';

const Title = () => (
  <Box
    backgroundColor="#F8F8FA"
    borderRadius="12px"
    paddingHorizontal={{ xs: '8x', lg: '8x' }}
    paddingBottom={{ xs: '2x' }}
    marginHorizontal={{ xs: '8x', lg: ' 0' }}
    marginTop={{ xs: '32x' }}
    width="-webkit-fill-available"
    minHeight={{ xs: 'auto', lg: 'inherit' }}
  >

    <Box
      position="relative"
      top={{ xs: '-40px' }}
      display="flex"
      justifyContent={{ xs: 'left' }}
      marginRight={{ xs: '4x', lg: '4x' }}
    >
      <Image
        alt="Icono de una bolsa"
        src="components/rates/analysis-new.svg"
        width={{ xs: '68px', md: '71px' }}
        height={{ xs: '68px', md: '71px' }}
        webp={false}
      />
    </Box>

    <Typography
      variant={useBreakpointsMediaUp('md') ? 'heading4' : 'heading3'}
      intlKey="HOME.LITE_VERSION.RATES.TITLE"
      marginBottom={{ xs: '8x', md: '6x', lg: '4x' }}
      marginTop={{ xs: '-4x', lg: '-6x' }}
      whiteSpace={{ xs: 'normal', lg: 'pre-line' }}
      textAlign={{ xs: 'left' }}
      maxWidth={{ xs: '200px', md: '280px' }}
    />
  </Box>
);

export default Title;
