import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';

export const StyledLink = styled(Link)`
  /* stylelint-disable property-no-vendor-prefix */
  background: ${props => props.gradient};
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  
  & svg {
    color: ${props => props.color};
  }
`;
