import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Link from '@nubank/nuds-web/components/Link/Link';
import { htmlToText } from 'html-to-text';

import { registerHomeEvent } from '../../utils/gtm';

const FAQSection = ({
  title, questions, openFirst, openLast,
}) => {
  const getInitiallyOpen = i => {
    if (openFirst && i === 0) {
      return true;
    }

    if (openLast && i === questions.length - 1) {
      return true;
    }

    return false;
  };

  const handleLinkTracking = () => {
    registerHomeEvent({
      eventAction: 'link click',
      eventLabel: 'FAQ - otras preguntas',
    });
  };

  const handleQuestionTracking = label => {
    registerHomeEvent({
      eventAction: 'faq click',
      eventLabel: label,
    });
  };

  return (
    <Box
      tag="section"
      backgroundColor="white.dark"
      display="flex"
      flexDirection="column"
      paddingHorizontal={{ xs: '8x', lg: '16x' }}
      paddingVertical={{ xs: '12x', lg: '32x' }}
    >
      <Typography intlKey={title} variant="heading2" />

      {questions.map(({ id, question, answer }, index) => (
        <Box
          marginTop={{ xs: '16x', md: 60, lg: 56 }}
          key={id}
        >
          <Collapse
            id={id}
            initiallyOpen={getInitiallyOpen(index)}
            onChange={() => handleQuestionTracking(question)}
          >
            <Collapse.Header>
              <Typography color="black.light" variant="subtitle1" strong>
                {question}
              </Typography>
            </Collapse.Header>
            <Collapse.Body>
              <Typography variant="subtitle1">
                {htmlToText(answer)}
              </Typography>
            </Collapse.Body>
          </Collapse>
        </Box>
      ))}

      <Link
        marginTop={{ xs: '8x', md: 60 }}
        variant="action"
        intlKey="HOME.FAQ.LINK"
        href="/ayuda"
        width="fit-content"
        onClick={() => handleLinkTracking()}
      />
    </Box>
  );
};

FAQSection.defaultProps = {
  title: 'HOME.FAQ.TITLE',
  openFirst: false,
  openLast: true,
};

FAQSection.propTypes = {
  openFirst: PropTypes.bool,
  openLast: PropTypes.bool,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      id: PropTypes.string,
      question: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
};

export default FAQSection;
