import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';

import CardDesktop from './CardDesktop';
import { cardsInfo } from './cardsInfo';

function CardsBoxDesktop() {
  const [credit, debit] = cardsInfo;

  return (
    <Box
      display={{ xs: 'none', md: 'flex' }}
      marginTop="18x"
      marginBottom="180px"
      tag="section"
      flexDirection="row"
      justifyContent="center"
      key="credit"
      paddingHorizontal="12x"
    >
      {/* CREDIT CARD */}

      <Box
        marginRight={{ md: '90px', lg: '100px' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CardDesktop {...credit} />
      </Box>

      {/* DEBIT CARD */}

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        key="debit"
        minWidth={{ xs: 'auto', lg: '380px' }}
      >
        <CardDesktop {...debit} />
      </Box>
    </Box>
  );
}

export default CardsBoxDesktop;
