import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import styled from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

import { StyledLink } from './StyledLink';
import { StyledTypography } from './StyledTypography';
import CardsBoxDesktop from './CardsBoxDesktop';
import { cardsInfo } from './cardsInfo';

const StyledBox = styled(Box)`
  .splide__pagination {
    display: flex;
    justify-content: left;
    padding-top: 24px;
    padding-left: 32px;
  }

  .splide__pagination__page {
    border-radius: 12px;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-color: ${nuDSColor('black.defaultT20')};

    &.is-active {
      width: 16px;
      background-color: ${nuDSColor('primary')};
    }
  }
`;

function CardsCarousel() {
  return (
    <>
      {/* MOBILE CAROUSEL */}

      <StyledBox
        paddingLeft={{ xs: '0', md: '12x' }}
        marginVertical={{ xs: '12x', md: '18x', lg: '24x' }}
        tag="section"
        display={{ xs: 'flex', md: 'none' }}
        justifyContent={{ xs: 'left', md: 'center' }}
        overflow="hidden"
      >
        <Splide
          aria-label="cards-carousel"
          options={{
            arrows: false,
            padding: { left: '32px', right: '23%' },
            gap: '32px',
            autoWidth: true,
            mediaQuery: 'min',
            breakpoints: {
              400: { padding: { right: '33%' } },
              520: { padding: 0 },
            },
          }}
        >

          {cardsInfo.map(card => (
            <SplideSlide key={card.id}>
              <Link href={card.href} noStyle>
                <Image
                  src={card.image}
                  webP={false}
                  alt={card.alt}
                  width="100%"
                  height="auto"
                />

                <StyledTypography
                  gradient={card.gradient}
                  marginVertical="8x"
                  variant="heading1"
                  whiteSpace="pre-line"
                >
                  {card.name}
                </StyledTypography>
              </Link>

              <StyledLink
                variant="action"
                href={card.href}
                width="fit-content"
                color="primary"
                typographyProps={{ color: card.color }}
                marginBottom={{ xs: '2x', lg: '0x' }}
                iconProps={{ name: 'arrow-up-right' }}
                gradient={card.gradient}
              >
                Conoce más
              </StyledLink>
            </SplideSlide>
          ))}
        </Splide>

      </StyledBox>

      {/* DESKTOP ONLY VISIBLE BLOCK */}

      <CardsBoxDesktop />

    </>
  );
}

export default CardsCarousel;
