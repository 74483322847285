import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix */
  background: ${props => props.gradient};
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`;
