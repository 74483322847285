export const cardsInfo = [{
  id: 1,
  name: 'Tarjeta de \n Crédito Nu',
  image: 'lite-version/credit-card.svg',
  imageLg: 'lite-version/credit-card-lg.svg',
  alt: 'Imagen de la tarjeta de Crédito Nu',
  href: '/credito',
  gradient: 'linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%)',
  color: '#820AD1',
}, {
  id: 2,
  name: 'Cuenta \n Nu',
  image: 'lite-version/debit-card.svg',
  imageLg: 'lite-version/debit-card-lg.svg',
  alt: 'Imagen de la tarjeta de Debito Nu',
  href: '/cuenta',
  gradient: 'linear-gradient(106.94deg, #C9AFFE 1.76%, #7B4DD6 30.52%)',
  color: '#7B4DD6',
}];
