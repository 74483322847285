import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Cuenta from './patterns/Cuenta';
import Credito from './patterns/Credito';
import Title from './patterns/Title';

const Rates = props => {
  const { hideCuenta, hideCredito } = props;

  const getTitleHeight = useRef(null);
  const getCuentaHeight = useRef(null);
  const getCreditoHeight = useRef(null);
  const [blocksHeight, setBlocksHeight] = useState(0);
  const cuentaDefault = !!((hideCuenta && hideCredito));
  const hideAProduct = hideCredito || hideCuenta;
  const bothProducts = !hideAProduct && !cuentaDefault;

  // Set same height for all blocks
  useLayoutEffect(() => {
    const blocksHeightArr = [];
    blocksHeightArr.push((getTitleHeight.current.offsetHeight - 127));
    blocksHeightArr.push(getCuentaHeight.current.offsetHeight);
    blocksHeightArr.push(getCreditoHeight.current.offsetHeight);

    const sortedBlocksHeight = blocksHeightArr.sort().reverse();
    setBlocksHeight(sortedBlocksHeight[0]);
  }, []);

  return (
    <Box
      tag="section"
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      marginBottom={{ xs: '12x', lg: '0' }}
      alignItems="center"
      paddingHorizontal={{ xs: '0', lg: '8vw', xl: '16vw' }}
    >
      <Grid
        paddingLeft={{ xs: '0', lg: '0x' }}
        paddingRight={{ xs: '0', lg: '0' }}
      >

        {/* CONTENT BOX */}

        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '2 / span 10' }}
          gridRow="1"
        >

          {/* Title */}
          <Box
            ref={getTitleHeight}
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
          >

            <Box
              width={{ xs: '100%', md: '57%', lg: bothProducts ? '37%' : '55%' }}
              minHeight={{ xs: 'auto', lg: `${blocksHeight}px` }}
            >
              <Title />
            </Box>

            {/* Cuenta */}
            <Box
              ref={getCuentaHeight}
              display={hideCuenta && !cuentaDefault ? 'none' : 'auto'}
              marginTop={{ xs: '0', lg: '120px' }}
              width={{ xs: '100%', md: '57%', lg: bothProducts ? '30%' : '43%' }}
              minHeight={{ xs: 'auto', lg: `${blocksHeight}px` }}
            >
              <Cuenta />
            </Box>

            {/* Credito */}
            <Box
              ref={getCreditoHeight}
              display={hideCredito ? 'none' : 'auto'}
              marginTop={{ xs: '0', lg: '120px' }}
              width={{ xs: '100%', md: '57%', lg: bothProducts ? '30%' : '43%' }}
              minHeight={{ xs: 'auto', lg: `${blocksHeight}px` }}
            >
              <Credito />
            </Box>
          </Box>

        </Grid.Child>

      </Grid>

    </Box>
  );
};

Rates.defaultProps = {
  hideCredito: false,
  hideCuenta: false,
};

Rates.propTypes = {
  hideCredito: PropTypes.bool,
  hideCuenta: PropTypes.bool,
};

export default Rates;
