import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';
import styled, { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix, property-no-unknown */
  background: linear-gradient(90deg, rgb(159 45 235 / 100%), rgb(98 0 163 / 100%) );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledBox = styled(Box)`
  img {
    margin: 0 auto;
    padding-bottom: 32px;
    transition: transform .2s; /* Animation */
    ${breakpointsMedia({
    lg: css`
      margin: 0;
      padding-bottom: 0;
      width: auto;
      height: auto;
    `,
  })}
  }

  img:hover {
    transform: scale(1.2);
  }

  gap: 80px;
`;

function Press() {
  return (
    <Box
      tag="section"
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'space-between', md: 'space-evenly' }}
      alignItems="center"
      backgroundColor="white"
      paddingVertical={{ xs: '18x', md: '24x' }}
      marginBottom={{ xs: '0', lg: '16x' }}
      marginTop={{ xs: '0', lg: '100px' }}
    >

      <Typography
        id="experimentTest"
        display={{ xs: 'block', lg: 'none' }}
        intlKey="HOME.LITE_VERSION.PRESS.TITLE"
        variant="heading2"
        textAlign="center"
        marginBottom="12x"
        paddingHorizontal={{ xs: '8x', md: '12x', lg: '16x' }}
        intlValues={{
          highlight: (
            <StyledTypography
              tag="span"
              intlKey="HOME.LITE_VERSION.PRESS.TITLE.HIGHLIGHT"
              variant="heading2"
            />
          ),
        }}
        whiteSpace="pre-line"
      />

      <Typography
        display={{ xs: 'none', lg: 'block' }}
        intlKey="HOME.LITE_VERSION.PRESS.TITLE.DESKTOP"
        variant="heading3"
        textAlign="center"
        marginBottom="12x"
        paddingHorizontal={{ xs: '8x', md: '12x', lg: '16x' }}
        intlValues={{
          highlight: (
            <StyledTypography
              tag="span"
              intlKey="HOME.LITE_VERSION.PRESS.TITLE.HIGHLIGHT"
              variant="heading3"
            />
          ),
        }}
        whiteSpace="pre-line"
      />

      <StyledBox
        display={{ xs: 'block', lg: 'flex' }}
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent={{ xs: 'center', lg: 'center' }}
        alignItems="center"
        width="100%"
        marginTop={{ xs: '0', lg: '12x' }}
        paddingHorizontal={{ xs: '0', lg: '12x' }}
      >

        {/* EL PAIS LINK */}
        <Link
          href="https://elpais.com/mexico/economia/2024-04-16/nubank-inyectara-100-millones-de-dolares-a-su-operacion-en-mexico.html"
          noStyle
          margin="0 auto"
        >
          <Image
            alt="Logo de El Pais"
            src="home32/press-and-community/el-pais.png"
            loading="lazy"
            maxWidth={{ xs: '95px', lg: '120px' }}
            height={{ xs: '100%', lg: '30px' }}
            webp
          />
        </Link>

        {/* BUSINESS INSIDER LINK */}

        <Link
          href="https://businessinsider.mx/nu-mexico-lanza-inversiones-de-plazo-fijo-y-retoma-oferta-de-15-anualizado/"
          noStyle
          typographyProps={{ paddingBottom: '8x' }}
        >
          <Image
            alt="Logo de Business Insider"
            src="home32/press-and-community/business-insider.png"
            maxWidth={{ xs: '130px', lg: '165px' }}
            height={{ xs: '100%', lg: '75px' }}
            loading="lazy"
            webp
          />
        </Link>

        {/* TIME LINK */}

        <Link
          href="https://time.com/6980420/nubank/"
          noStyle
          typographyProps={{ marginBottom: '4x' }}
          marginBottom="4x"
        >
          <Image
            alt="Logo de Time"
            src="home32/press-and-community/time.png"
            maxWidth={{ xs: '82px', lg: '128px' }}
            height={{ xs: '100%', lg: '40px' }}
            loading="lazy"
            webp
          />
        </Link>

      </StyledBox>
    </Box>
  );
}

export default Press;
