import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled from 'styled-components';

import AppStoreBadge from '../../../../components/AppStoreBadge/AppStoreBadge';
import AppStoreButtonsContainer from '../../../Registration/patterns/DecisionStep/styles/AppStoreButtonsContainer';
import useMobileOS from '../../../../utils/hooks/useMobileOS';
import { downloadAppBtnLP } from '../../../Registration/tracking';

const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix, property-no-unknown */
  background: linear-gradient(90deg, rgb(159 45 235 / 100%), rgb(98 0 163 / 100%) );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Download = () => {
  const { formatMessage } = useIntl();
  const mobileSystem = useMobileOS();
  const isDesktop = useBreakpointsMediaUp('lg');

  const redirectToStore = (store, operatingSystem, page) => {
    window.open(formatMessage({ id: `HOME.DOWNLOAD_APP.${store}.PATH` }), '_blank');
    downloadAppBtnLP(operatingSystem, page);
  };

  return (
    <Box
      tag="section"
      display="flex"
      flexDirection={{ xs: 'column' }}
      alignItems={{ xs: 'center' }}
      justifyContent={{ xs: 'auto', md: 'space-between' }}
      marginTop={{ xs: '24x', md: '200px', lg: '0' }}
      marginBottom={{ xs: '12x', lg: '80px' }}
    >

      <Typography
        intlKey="HOME.DOWNLOAD_APP.TITLE"
        variant="heading2"
        tag="h3"
        textAlign="center"
        intlValues={{
          br: <br />,
          highlight: (
            <StyledTypography
              tag="span"
              variant="heading2"
              intlKey="HOME.DOWNLOAD_APP.TITLE.HIGHLIGHT"
              textAlign="center"
            />
          ),
        }}
      />

      <Box
        display="flex"
        flexDirection={{ xs: 'column' }}
        alignItems={{ xs: 'center' }}
        marginTop={{ xs: '8x', md: '12x', lg: '16x' }}
        marginLeft={!isDesktop && mobileSystem !== 'other' ? '4x' : '0x'}
      >

        <AppStoreButtonsContainer>
          { isDesktop || (mobileSystem === 'ios' || mobileSystem === 'other')
            ? (
              <Box onClick={() => redirectToStore('APPLESTORE', 'ios', 'home')}>
                <AppStoreBadge platform="ios" variant="dark" />
              </Box>
            )
            : null}
          { isDesktop || (mobileSystem === 'android' || mobileSystem === 'other')
            ? (
              <Box onClick={() => redirectToStore('GOOGLESTORE', 'android', 'home')}>
                <AppStoreBadge platform="android" variant="dark" />
              </Box>
            )
            : null}
        </AppStoreButtonsContainer>
      </Box>

    </Box>
  );
};

export default Download;
